import React from "react"
import * as styles from "./styles"
import classNames from "classnames"

const CalendarDate = ({ date }) => {
    const time = new Date(date)
    const month = time.toLocaleString("en-GB", { month: "short" })
    const currentDate = Date.now()

    const isInTheFuture = time > currentDate

    return (
        <div className={styles.Calendar}>
            <h2
                className={classNames(styles.Calendar__Day, {
                    [styles.Calendar__DayActive]: isInTheFuture,
                })}
            >
                {time.getDate()}
            </h2>
            <strong
                className={styles.Calendar__Month}
                dangerouslySetInnerHTML={{ __html: month }}
            />
        </div>
    )
}

export default CalendarDate
