import React from "react"
import PropTypes from "prop-types"

import * as styles from "./styles"

import { graphql, Link, useStaticQuery } from "gatsby"
import { createLocalLink } from "../../../utils/createLocalLink"
import dataAttributes from "../../../utils/dataAttributes"
import { createGtmTagClass } from "../../../utils"

const Breadcrumbs = ({ page, link, title }) => {
    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    generalSettings {
                        url
                    }
                }
            }
        `
    )

    const {
        generalSettings: { url: wpUrl },
    } = data.wp

    const createExcerpt = () => {
        if (title.length > 35) {
            title = title.slice(0, 25)
            const lastCharacter = title.substr(title.length - 1)
            if (
                lastCharacter !== "?" &&
                lastCharacter !== "!" &&
                lastCharacter !== "."
            ) {
                title = `${title}...`
            }
        }
        return title
    }

    return (
        <ul
            className={`${styles.Breadcrumbs} ${createGtmTagClass(
                "Breadcrumbs"
            )}`}
        >
            <li {...dataAttributes("breadcrumbs‐link", page)}>
                <Link
                    dangerouslySetInnerHTML={{ __html: page }}
                    to={createLocalLink(link, wpUrl)}
                />
            </li>
            <li className={styles.Breadcrumbs__Spacer}>/</li>
            <li dangerouslySetInnerHTML={{ __html: createExcerpt() }} />
        </ul>
    )
}

Breadcrumbs.propTypes = {
    page: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default Breadcrumbs
