import React from "react"
import propTypes from "prop-types"
import classNames from "classnames"

import * as styles from "./styles"
import EventTile from "../event-tile/EventTile"
import InsightTile from "../insight-tile/InsightTile"

const PostsList = ({ posts, type }) => {
    return (
        <div className="row">
            {posts?.map(
                ({
                    title,
                    slug,
                    content,
                    acf: {
                        type: postType,
                        date,
                        place,
                        podcastid,
                        videoid,
                        image: {
                            altText,
                            localFile: {
                                childImageSharp: { gatsbyImageData: image },
                            },
                        },
                    },
                }) => (
                    <div
                        key={title}
                        className={classNames(
                            `small-12 large-4`,
                            styles.PostsList
                        )}
                    >
                        <>
                            {type === "events" ? (
                                <EventTile
                                    image={image}
                                    imageAlt={altText}
                                    title={title}
                                    type={postType}
                                    slug={slug}
                                    place={place}
                                    excerpt={content}
                                    date={date}
                                />
                            ) : (
                                <InsightTile
                                    image={image}
                                    imageAlt={altText}
                                    title={title}
                                    type={postType}
                                    slug={slug}
                                    date={date}
                                    mediaId={videoid ?? podcastid}
                                />
                            )}
                        </>
                    </div>
                )
            )}
        </div>
    )
}

PostsList.propTypes = {
    posts: propTypes.array.isRequired,
    type: propTypes.string.isRequired,
}

export default PostsList
