import React from "react"
import Person from "../../../common/person/Person"
import * as styles from "./styles"

const People = ({ people, contactText, calendarText, emailSubject }) => {
    return (
        <>
            {people?.map(data => {
                const {
                    id,
                    title,
                    acf: {
                        photo: {
                            localFile: {
                                childImageSharp: { gatsbyImageData: photo },
                            },
                        },
                        email,
                        linkedinlink,
                        calendarLink,
                        position,
                    },
                } = data.person

                return (
                    <div className={styles.People__Person} key={id}>
                        <Person
                            photo={photo}
                            name={title}
                            position={position}
                            contactText={contactText}
                            calendarText={calendarText}
                            calendarLink={calendarLink}
                            email={email}
                            emailsubject={emailSubject}
                            linkedinLink={linkedinlink}
                        />
                    </div>
                )
            })}
        </>
    )
}

export default People
