import { useStaticQuery, graphql } from "gatsby"

const useQuery = () => {
    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    contact {
                        acf {
                            contactEn {
                                contactEnFormId
                                contactEnContent
                                contactEnHeader
                                contactEnSubheader
                                contactEnEmailSubject
                                contactEnPersonText
                                contactEnCalendarText
                                contactEnSubmissionHeader
                                contactEnSubmissionText
                            }
                            contactDe {
                                contactDeContent
                                contactDeFormId
                                contactDeHeader
                                contactDeSubheader
                                contactDeEmailSubject
                                contactDePersonText
                                contactDeCalendarText
                                contactDeSubmissionHeader
                                contactDeSubmissionText
                            }
                            people {
                                person {
                                    ... on WpOrganizer {
                                        id
                                        title
                                        acf {
                                            photo {
                                                ...gatsbyFluidImage
                                            }
                                            email
                                            linkedinlink
                                            calendarLink
                                            position
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const {
        contactEnFormId,
        contactEnContent,
        contactEnHeader,
        contactEnSubheader,
        contactEnEmailSubject,
        contactEnPersonText,
        contactEnCalendarText,
        contactEnSubmissionHeader,
        contactEnSubmissionText,
    } = data.wp.contact.acf.contactEn

    const {
        contactDeContent,
        contactDeFormId,
        contactDeHeader,
        contactDeSubheader,
        contactDeEmailSubject,
        contactDePersonText,
        contactDeCalendarText,
        contactDeSubmissionHeader,
        contactDeSubmissionText,
    } = data.wp.contact.acf.contactDe

    return {
        people: data.wp.contact.acf.people,
        en: {
            formId: contactEnFormId,
            content: contactEnContent,
            header: contactEnHeader,
            subheader: contactEnSubheader,
            emailSubject: contactEnEmailSubject,
            contactText: contactEnPersonText,
            calendarText: contactEnCalendarText,
            submissionHeader: contactEnSubmissionHeader,
            submissionText: contactEnSubmissionText,
        },
        de: {
            formId: contactDeFormId,
            content: contactDeContent,
            header: contactDeHeader,
            subheader: contactDeSubheader,
            emailSubject: contactDeEmailSubject,
            contactText: contactDePersonText,
            calendarText: contactDeCalendarText,
            submissionHeader: contactDeSubmissionHeader,
            submissionText: contactDeSubmissionText,
        },
    }
}
export default useQuery
