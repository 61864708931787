import React from "react"
import PropTypes from "prop-types"
import dataAttributes from "../../../utils/dataAttributes"
import { GatsbyImage } from "gatsby-plugin-image"

import Icon from "../../ui/icons/Icon"
import LinkedinIcon from "../../../images/linkedin.svg"
import * as styles from "./styles"

const Person = ({
    photo,
    name,
    position,
    linkedinLink,
    contactText,
    email,
    emailsubject,
    calendarLink,
    calendarText,
}) => {
    return (
        <div className={styles.Person}>
            <div className={styles.Person__Container}>
                <div className={styles.Person__Photo}>
                    <GatsbyImage alt={name} image={photo} />
                </div>
                <div>
                    <p className={styles.Person__Name}>{name}</p>
                    <p className={styles.Person__Position}>{position}</p>
                    <div className={styles.Person__Contact}>
                        <a
                            target="_blank"
                            rel="noopener noreferrer nofollow"
                            className={`${styles.Person__SocialIcon}`}
                            href={linkedinLink}
                            {...dataAttributes(
                                "contact-people-box-linkedin",
                                "Linkedin profile"
                            )}
                        >
                            <img src={LinkedinIcon} alt="linkedin" />
                        </a>
                        <a
                            {...dataAttributes(
                                "contact-people-box-email",
                                "Contact me"
                            )}
                            className={styles.Person__MailLink}
                            href={`mailto: ${email}?subject=${emailsubject}`}
                        >
                            {contactText}
                            <Icon icon="arrow2" />
                        </a>
                    </div>
                </div>
            </div>
            {calendarText && calendarLink && (
                <a
                    className={styles.Person__CalendarLink}
                    {...dataAttributes(
                        "contact-people-box-calendar",
                        "Lets have a call"
                    )}
                    href={calendarLink}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Icon icon="calendar" />

                    {calendarText}
                </a>
            )}
        </div>
    )
}

Person.propTypes = {
    photo: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    linkedinlink: PropTypes.string,
    contactText: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    emailsubject: PropTypes.string.isRequired,
    calendarText: PropTypes.string.isRequired,
    calendarLink: PropTypes.string,
}

export default Person
