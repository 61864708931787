import React from "react"
import * as styles from "./styles"
import { H1 } from "../../ui/headers/Headers"
import { LinearPattern } from "../../ui/linear-pattern/styles"
import People from "./people/People"
import HubspotForm from "../../functional/hubspot/HubspotForm"

const Presentation = ({
    submissionHeader,
    submissionText,
    header,
    subheader,
    content,
    formId,
    people,
    contactText,
    calendarText,
    emailSubject,
}) => {
    return (
        <section id="contact" className={`${styles.Presentation} t-Dark`}>
            <LinearPattern />
            <div className={`row l-Inner ${styles.Presentation__Row}`}>
                <div className="small-12">
                    <h4 dangerouslySetInnerHTML={{ __html: subheader }} />
                    <H1
                        className={styles.Presentation__Header}
                        content={header}
                    />
                </div>
                <div
                    className={`small-12 medium-5 xlarge-6 ${styles.Presentation__PeopleContainer}`}
                >
                    <p
                        className={styles.Presentation__Content}
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                    <People
                        contactText={contactText}
                        calendarText={calendarText}
                        emailSubject={emailSubject}
                        people={people}
                    />
                </div>
                <div
                    className={`small-12 medium-6 medium-offset-1 xlarge-5 xlarge-offset-1 ${styles.Presentation__Form}`}
                >
                    <HubspotForm
                        header={submissionHeader}
                        text={submissionText}
                        formId={formId}
                        slug="contact"
                        dark
                    />
                </div>
            </div>
        </section>
    )
}

export default Presentation
