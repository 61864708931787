import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import * as styles from "./SubmitModal.module.scss"
import {H1} from "../../../ui/headers/Headers"
import classnames from "classnames";
import PropTypes from "prop-types";

const SubmitModal = ({header, text, dark}) => {
    const data = useStaticQuery(
        graphql`
            query {
                wp {
                    contact {
                        acf {
                            contactEn {
                                contactEnSubmissionHeader
                                contactEnSubmissionText
                            }
                        }
                    }
                }
            }
        `
    )
    const {
        contactEnSubmissionHeader: defaultHeader,
        contactEnSubmissionText: defaultText,
    } = data.wp.contact.acf.contactEn

    header = header ? header : defaultHeader
    text = text ? text : defaultText

    return (
        <div className={classnames(
            styles.SubmitModal,
            {'t-Dark': dark},
        )}>
            <div className={styles.SubmitModal__Icon}/>
            <h3 dangerouslySetInnerHTML={{__html: header}}/>
            <div
                dangerouslySetInnerHTML={{__html: text}}
            />
        </div>
    )
}

SubmitModal.propTypes = {
    header: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    dark: PropTypes.bool,
}

export default SubmitModal
