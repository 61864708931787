import React from "react"
import propTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import * as styles from "./styles"
import routes from "../../../../config/routes"
import CalendarDate from "../calendar/CalendarDate"
import Label from "../../../ui/label/Label"
import Icon from "../../../ui/icons/Icon"
import OutlineButton from "../../../ui/outline-button/OutlineButton"

const EventTile = ({
    image,
    imageAlt,
    title,
    excerpt,
    date,
    type,
    slug,
    place,
    showLock,
    materialsForm,
    setFormData,
}) => {
    const generateExcerpt = () => {
        const limit = 150
        if (!excerpt) return
        if (excerpt.length > limit) {
            return `${excerpt.slice(0, limit)} <span> ...</span>`
        }
        return excerpt
    }

    const formattedExcerpt = generateExcerpt()

    return (
        <div className={styles.EventTile}>
            <div className={styles.EventTile__Wrapper}>
                {showLock ? (
                    <div className={styles.EventTile__Inner}>
                        <div className={styles.EventTile__Image}>
                            <GatsbyImage
                                alt={imageAlt !== "" ? imageAlt : title}
                                image={image}
                            />
                            <div
                                onClick={() =>
                                    setFormData({ ...materialsForm, title })
                                }
                                className={styles.EventTile__Locked}
                            >
                                <Icon icon="padlock" size={24} />
                            </div>
                        </div>
                        <div className={styles.EventTile__Content}>
                            <div className={styles.EventTile__Label}>
                                <Label type={type} />
                            </div>
                            <div className={styles.EventTile__Top}>
                                <div className={styles.EventTile__Calendar}>
                                    <CalendarDate date={date} />
                                </div>
                                <Link to={`${routes.events}/${slug}`}>
                                    <h3
                                        dangerouslySetInnerHTML={{
                                            __html: title,
                                        }}
                                    />
                                </Link>
                            </div>
                            {formattedExcerpt && (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: formattedExcerpt,
                                    }}
                                />
                            )}

                            <div className={styles.EventTile__Bottom}>
                                {place && (
                                    <div className={styles.EventTile__Place}>
                                        <Icon icon="map-marker" size={24} />
                                        {place}
                                    </div>
                                )}
                                <OutlineButton
                                    onClick={() =>
                                        setFormData({ ...materialsForm, title })
                                    }
                                    text="Sign in to watch"
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <Link
                        className={styles.EventTile__Link}
                        to={`${routes.events}/${slug}`}
                    >
                        <div className={styles.EventTile__Image}>
                            <GatsbyImage
                                alt={imageAlt !== "" ? imageAlt : title}
                                image={image}
                            />
                        </div>
                        <div className={styles.EventTile__Content}>
                            <div className={styles.EventTile__Label}>
                                <Label type={type} />
                            </div>
                            <div className={styles.EventTile__Top}>
                                <div className={styles.EventTile__Calendar}>
                                    <CalendarDate date={date} />
                                </div>
                                <h3
                                    dangerouslySetInnerHTML={{ __html: title }}
                                />
                            </div>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: formattedExcerpt,
                                }}
                                className={styles.EventTile__Excerpt}
                            />
                            <div className={styles.EventTile__Bottom}>
                                {place && (
                                    <div className={styles.EventTile__Place}>
                                        <Icon icon="map-marker" size={24} />
                                        {place}
                                    </div>
                                )}
                                <OutlineButton text="Read more" />
                            </div>
                        </div>
                    </Link>
                )}
            </div>
        </div>
    )
}

EventTile.propTypes = {
    title: propTypes.string.isRequired,
    imageAlt: propTypes.string.isRequired,
    image: propTypes.object.isRequired,
    excerpt: propTypes.string.isRequired,
    date: propTypes.string.isRequired,
    slug: propTypes.string.isRequired,
    type: propTypes.string.isRequired,
    place: propTypes.string,
    showLock: propTypes.bool,
}

export default EventTile
