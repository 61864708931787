import React from "react"
import PropTypes from "prop-types"

import useQuery from "./useQuery"
import Presentation from "./Presentation"
const Contact = ({ props: { language, overwrittenContactPeople } }) => {
    const data = useQuery()
    const {
        formId,
        content,
        header,
        subheader,
        emailSubject,
        contactText,
        calendarText,
        submissionHeader,
        submissionText,
    } = data[language]
    const people = data.people

    return (
        <Presentation
            formId={formId}
            subheader={subheader}
            header={header}
            content={content}
            submissionHeader={submissionHeader}
            submissionText={submissionText}
            people={
                overwrittenContactPeople ? overwrittenContactPeople : people
            }
            contactText={contactText}
            calendarText={calendarText}
            emailSubject={emailSubject}
        />
    )
}

Contact.propTypes = {
    language: PropTypes.string,
}

export default Contact
