import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import * as styles from "./styles"
import Icon from "../icons/Icon"

import { getLabelIcon, getLabelType } from "../../../utils/label"

const Label = ({ small = false, type }) => {
    return (
        <div
            className={classnames(styles.Label, {
                [styles.LabelIsOnline]: type === "online",
                [styles.LabelIsOffline]: type === "offline",
                [styles.LabelIsHybrid]: type === "hybrid",
                [styles.LabelIsWebinar]: type === "webinar",
                [styles.LabelIsPodcast]: type === "podcast",
                [styles.LabelIsWebinarPodcast]: type === "webinar-podcast",
                [styles.LabelIsInterview]: type === "interview",
                [styles.LabelIsSmall]: small
            })}
        >
            <Icon icon={getLabelIcon(type)} />

            {getLabelType(type)}
        </div>
    )
}

Label.propTypes = {
    type: PropTypes.string.isRequired,
    small: PropTypes.bool
}

export default Label
