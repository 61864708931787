/**
 * Reformat date to localized english string in format DD MMM YYYY
 *
 * @param date {string}
 * @returns {string}
 */
export const reformatDate = date => {
    // Create date object
    let formatedDate = new Date(date.replace(/-/g, "/"))

    // Create localization
    const dateTimeFormat = new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "short",
        day: "2-digit",
    })

    // Destructure localized format
    const [
        { value: month },
        ,
        { value: day },
        ,
        { value: year },
    ] = dateTimeFormat.formatToParts(formatedDate)

    // Return string divided by one space
    return `${day} ${month} ${year}`
}
