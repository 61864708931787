import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import Icon from "../icons/Icon"
import * as styles from "./styles"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import dataAttributes from "../../../utils/dataAttributes"

const OutlineButton = ({
    text,
    onClick,
    href,
    small,
    medium = false,
    big,
    iconLeft,
    white,
    dataAtts,
    external,
}) => {
    return (
        <>
            {href ? (
                <>
                    {external ? (
                        <a
                            className={classnames(styles.OutlineButton, {
                                [styles.OutlineButtonSmall]: small,
                                [styles.OutlineButtonMedium]: medium,
                                [styles.OutlineButtonBig]: big,
                                [styles.OutlineButtonIconLeft]: iconLeft,
                                [styles.OutlineButtonWhite]: white,
                            })}
                            href={href}
                            {...(dataAtts
                                ? {
                                      ...dataAttributes(
                                          dataAtts.click,
                                          dataAtts.name
                                      ),
                                  }
                                : null)}
                        >
                            {text}
                            <Icon icon="arrow2" />
                        </a>
                    ) : (
                        <AnchorLink
                            className={classnames(styles.OutlineButton, {
                                [styles.OutlineButtonSmall]: small,
                                [styles.OutlineButtonBig]: big,
                                [styles.OutlineButtonMedium]: medium,
                                [styles.OutlineButtonIconLeft]: iconLeft,
                                [styles.OutlineButtonWhite]: white,
                            })}
                            to={href}
                            {...(dataAtts
                                ? {
                                      ...dataAttributes(
                                          dataAtts.click,
                                          dataAtts.name
                                      ),
                                  }
                                : null)}
                        >
                            {text}
                            <Icon icon="arrow2" />
                        </AnchorLink>
                    )}
                </>
            ) : (
                <button
                    className={classnames(styles.OutlineButton, {
                        [styles.OutlineButtonSmall]: small,
                        [styles.OutlineButtonBig]: big,
                        [styles.OutlineButtonIconLeft]: iconLeft,
                        [styles.OutlineButtonWhite]: white,
                    })}
                    onClick={onClick}
                    {...(dataAtts
                        ? { ...dataAttributes(dataAtts.click, dataAtts.name) }
                        : null)}
                >
                    {text}
                    <Icon icon="arrow2" />
                </button>
            )}
        </>
    )
}

OutlineButton.propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    href: PropTypes.string,
    iconLeft: PropTypes.bool,
    white: PropTypes.bool,
    external: PropTypes.bool,
}

export default OutlineButton
