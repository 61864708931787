import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"

import { isBrowser } from "../../../utils"

import SubmitModal from "./submit-modal/SubmitModal"
import { HubspotContext } from "../../../context/HubspotContext"

const HubspotForm = ({
    formId,
    slug,
    header,
    text,
    personalDataInfo,
    dark,
}) => {
    const [isSubmitted, setSubmitted] = useState(false)
    const hbsptObject = useContext(HubspotContext)
    const formSubmissionHandler = event => {
        if (event.data.type === "hsFormCallback" && event.data.id === formId) {
            switch (event.data.eventName) {
                case "onFormSubmitted":
                    setSubmitted(true)
                    window.dataLayer.push({
                        event: "webform",
                        formId: event.data.id,
                        formName: slug,
                        formStatus: "Form Submited",
                    })
                    break
                case "onFormFailedValidation":
                    window.dataLayer.push({
                        event: "webform",
                        formId: event.data.id,
                        formName: slug,
                        formStatus: "Form Validation Failed",
                    })
                    break
                default:
                    break
            }
        }
    }

    const {
        wp: {
            forms: {
                acf: { formsPortalId },
            },
        },
    } = useStaticQuery(
        graphql`
            query {
                wp {
                    forms {
                        acf {
                            formsPortalId
                        }
                    }
                }
            }
        `
    )

    // Add listeners only on didMount
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        isBrowser() && window.addEventListener("message", formSubmissionHandler)
        return () => {
            window.removeEventListener("message", formSubmissionHandler)
        }
    }, [])

    // When hubspot script is available call create method
    useEffect(() => {
        hbsptObject &&
            hbsptObject.forms.create({
                portalId: formsPortalId,
                formId: formId,
                target: `.custom-${formId}`,
            })
    }, [hbsptObject])

    return (
        <>
            {isSubmitted ? (
                <SubmitModal header={header} text={text} dark={dark} />
            ) : (
                <>
                    <div className={`custom-${formId}`} />
                    {personalDataInfo && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: personalDataInfo,
                            }}
                            className="legal-consent-container"
                        />
                    )}
                </>
            )}
        </>
    )
}

HubspotForm.propTypes = {
    formId: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
}

export default HubspotForm
