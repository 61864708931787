export const getLabelType = type => {
    let labelType = type
    switch (type) {
        case "online":
            labelType = "Online event"
            break
        case "offline":
            labelType = "Onsite event"
            break
        case "hybrid":
            labelType = "Hybrid event"
            break
        case "webinar-podcast":
            labelType = "Webinar & podcast"
            break
        case "webinar":
            labelType = "Webinar"
            break
        case "podcast":
            labelType = "Podcast"
            break
        default:
            labelType = type
    }

    return labelType
}
