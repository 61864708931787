import { useContext } from "react"
import { PostsContext } from "../context/PostsContext"

const usePostsContext = () => {
    const { events, insights } = useContext(PostsContext)

    return { events, insights }
}

export default usePostsContext
