export const getLabelIcon = type => {
    let labelIcon = "presentation-chart"

    switch (type) {
        case "online":
            labelIcon = "presentation-chart"
            break
        case "hybrid":
            labelIcon = "presentation-chart"
            break
        case "webinar":
            labelIcon = "video-camera"
            break
        case "interview":
            labelIcon = "wave"
            break
        case "podcast":
            labelIcon = "headset"
            break
        case "webinar-podcast":
            labelIcon = "wave"
            break
        default:
            break
    }

    return labelIcon
}
