import React from "react"
import propTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import * as styles from "./styles"
import routes from "../../../../config/routes"
import Label from "../../../ui/label/Label"
import OutlineButton from "../../../ui/outline-button/OutlineButton"
import { reformatDate } from "../../../../utils"
import PlayButton from "./play-button/PlayButton"
import useModalContext from "../../../../hooks/useMediaContext"
import classnames from "classnames";

const InsightTile = ({ image, thumbnailImage, imageAlt, title, date, type, slug, mediaId, listView }) => {
    const { setMedia } = useModalContext()
    return (
        <div className={styles.InsightTile}>
            <div className={classnames(styles.InsightTile__Wrapper, {
                [styles.InsightTile__WrapperListView]: listView,
            })}>
                <div className={classnames(styles.InsightTile__Top,{
                    [styles.InsightTile__TopListView]: listView,
                })}>
                    <GatsbyImage
                        alt={imageAlt !== "" ? imageAlt : title}
                        image={image}
                    />
                    {listView &&
                    <GatsbyImage
                        alt={imageAlt !== "" ? imageAlt : title}
                        image={thumbnailImage}
                    />
                    }
                    {!listView &&
                        <PlayButton
                            onClick={() => {
                                setMedia({
                                    id: mediaId,
                                    active: true,
                                    type: type === "podcast" ? "audio" : "video",
                                })
                            }}
                            type={type}
                        />
                    }
                </div>
                <Link
                    className={classnames(styles.InsightTile__Link, {
                        [styles.InsightTile__LinkListView]: listView,
                    })}
                    to={`${routes.insights}/${slug}`}
                >
                    <div className={styles.InsightTile__Content}>
                        <div className={classnames(styles.InsightTile__Label, {
                            [styles.InsightTile__LabelListView]: listView,
                        })}>
                            <Label small={listView} type={type} />
                        </div>
                        <div className={classnames(styles.InsightTile__Date, {
                            [styles.InsightTile__DateListView]: listView,
                        })}>
                            {reformatDate(date)}
                        </div>
                        <h3 dangerouslySetInnerHTML={{ __html: title }} />
                        <div className={classnames(styles.InsightTile__Bottom, {
                            [styles.InsightTile__BottomListView]: listView,
                        })}>
                            <OutlineButton
                                href={`${routes.insights}/${slug}`}
                                text="Read more"
                                medium={listView}
                            />
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}
InsightTile.propTypes = {
    title: propTypes.string.isRequired,
    image: propTypes.object.isRequired,
    thumbnailImage: propTypes.object,
    imageAlt: propTypes.string.isRequired,
    date: propTypes.string.isRequired,
    slug: propTypes.string.isRequired,
    type: propTypes.string.isRequired,
}

export default InsightTile
