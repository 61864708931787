import React from "react"
import PropTypes from "prop-types"
import * as styles from "./styles"
import classnames from "classnames"

import PostsList from "../../common/post/list/PostsList"
import Button from "../../ui/button/Button"
import { H1 } from "../../ui/headers/Headers"
import routes from "../../../config/routes"
import usePostsContext from "../../../hooks/usePostsContext"

const ThreeTiles = ({
    props: {
        subheader,
        header,
        type,
        buttonText,
        customTiles,
        showCustomTiles,
        doubleBottomPadding,
        whiteHeader,
    },
}) => {
    const posts = usePostsContext()
    customTiles =
        showCustomTiles && customTiles
            ? customTiles.map(singleTile => {
                  if (singleTile.tile) {
                      return { ...singleTile.tile }
                  }
                  return singleTile
              })
            : null

    return (
        <section
            className={classnames(styles.ThreeTiles, {
                [styles.ThreeTilesDoubleBottomPadding]: doubleBottomPadding,
                [styles.ThreeTilesWhiteHeader]: whiteHeader,
            })}
        >
            <div className="l-Inner row">
                <div className="small-12">
                    <h4 dangerouslySetInnerHTML={{ __html: subheader }} />
                    <H1 content={header} />
                </div>

                <PostsList
                    type={type}
                    posts={customTiles ?? posts[type].latests}
                />

                {buttonText && (
                    <div className={styles.ThreeTiles__Button}>
                        <Button
                            small
                            internal
                            href={routes[type]}
                            iconRight="arrow2"
                        >
                            {buttonText}
                        </Button>
                    </div>
                )}
            </div>
        </section>
    )
}

ThreeTiles.propTypes = {
    props: PropTypes.exact({
        subtitle: PropTypes.string,
        header: PropTypes.string,
        type: PropTypes.string,
        buttonText: PropTypes.string,
        customTiles: PropTypes.array,
        doubleBottomPadding: PropTypes.bool,
        whiteHeader: PropTypes.bool,
    }).isRequired,
}

export default ThreeTiles
