import React from "react"
import propTypes from "prop-types"
import Icon from "../../../../ui/icons/Icon"
import * as styles from "./styles"

const PlayButton = ({ onClick, type }) => {
    return (
        <button className={styles.PlayButton} onClick={onClick}>
            {type === "podcast" ? (
                <Icon icon="microphone" size={24} />
            ) : (
                <div className={styles.PlayButton__Play} />
            )}
        </button>
    )
}

PlayButton.propTypes = {
    type: propTypes.string.isRequired,
    onClick: propTypes.func.isRequired,
}

export default PlayButton
